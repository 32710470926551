<template>
    <div class="container py-3">
        <Content url="privacy-policy" title="Privacy Policy" h="h1" />
    </div>
</template>
<script>
    import Content from '@/components/Content.vue';

    export default {
        name: 'PrivacyPolicy',
        components: { Content }
    }
</script>
