<template>
    <div v-if="!subscribed">
        <form @submit.prevent="subscribe">
            <div class="d-flex w-100 gap-2">
                <label for="newsletter1" class="visually-hidden">Email address</label>
                <input id="newsletter1" type="email" class="form-control" v-model="email" placeholder="Email address" required>
                <button class="btn btn-primary" type="button" @click.stop.prevent="subscribe()">Subscribe</button>
            </div>
        </form>
    </div>
    <div v-else>
        <p class="text-light">Thank you for subscribing to our newsletter!</p>
    </div>
</template>
<script>

    import axios from "axios";
    const options = {
        headers: { 'ua-platform': 'vue3js', 'Content-Type': 'application/json' }
    }

    export default {
        name: 'SubscribeForm',
        data() {
            return {
                subscribed: false,
                email: ""
            }
        },
        methods: {
            getSubscribed() {
                this.subscribed = JSON.parse(localStorage.getItem('subscribed'));
            },
            subscribe() {
                if (this.email && this.validate(this.email)) {
                    axios.post(process.env.VUE_APP_URL + 'api/subscribe', { email: this.email }, options)
                        .then(() => {
                            this.subscribed = true;
                            localStorage.setItem('subscribed', true);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            },
            validate(email) {
                const pattern = RegExp(
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                );

                return pattern.test(email);
            }
        },
        beforeMount() {
            this.getSubscribed();
        }
    }
</script>