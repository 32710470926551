<template>
    <nav v-if="cookieAlert" class="navbar navbar-expand-lg navbar-dark bg-black px-5 fixed py-3">
        <div class="navbar-nav ml-auto text-light">
            We use cookies 🍪 that are necessary for our website to function properly. Please check our&nbsp;<router-link class="link text-decoration-none" to="privacy-policy">privacy policy</router-link>&nbsp;for more details.
        </div>
        <div class="navbar-nav ms-auto">
            <button class="btn btn-primary" type="button" @click.stop.prevent="setCookieConsent()">I understand</button>
        </div>
    </nav>
</template>
<script>

    export default {
        name: 'NavBarCookies',
        data() {
            return {
                cookieAlert: true
            }
        },
        methods: {
            getCookieConsent() {
                let storedConsent = localStorage.getItem('cookieConsent');
                if (storedConsent) {
                    let consent = JSON.parse(storedConsent);
                    if (consent) {
                        this.cookieAlert = false;
                    }
                }
            },
            setCookieConsent() {
                console.log("consent");
                localStorage.setItem('cookieConsent', JSON.stringify({ cookieConsent: true }));
                this.cookieAlert = false;
            }
        },
        beforeMount() {
            this.getCookieConsent();
        }
    }
</script>