<template>
    <div class="container bg-white mb-5">
        <!-- <div class="row mt-2 mb-2 px-2">
            <h1>Datasets</h1>
        </div> -->
        <div class="row gx-4 gx-lg-5 mb-5">
            <!--create 2 columns-->
            <div class="col-md-6">
                <h4 class="card-title mb-4">Skin Atlas Datasets:</h4>
                <div class="card shadow">
                    <img class="card-img-top" style="object-fit: cover; width: 100%; max-height: 250px; overflow: hidden;"  src="images/Skin_atlas_layers.JPG" alt="">                    
                    <ul class="list-group list-group-flush">
                        
                        <router-link class="list-group-item list-group-item-action flex-column" to="/dataset/skin-atlas">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Skin Atlas</h5>
                            </div>
                            <p class="small py-2">The Skin Atlas is a comprehensive and spatially resolved, quantitative proteomic atlas of the different layers and cell types in healthy human skin comprising almost 11 000 proteins.</p>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">skin layers</div>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">healthy skin</div>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">proteome</div>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">DDA</div>
                        </router-link>
                        
                        <router-link class="list-group-item list-group-item-action flex-column" to="/dataset/skin-immune-cell-atlas">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Skin Immune Cell Atlas</h5>
                            </div>
                            <p class="small py-2">The Skin Immune Cell Atlas comprises a highly resolved quantitative proteomic atlas of the cellular subsets of human healthy skin including endothelial cells, melanocytes, mast cells, macrophages, dendritic cells and epidermal and dermal T cells.</p>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">immune cells</div>
                        </router-link>    

                        <router-link class="list-group-item list-group-item-action flex-column" to="/dataset/innate-lymphoid-cells">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Innate Lymphoid Cells</h5>
                            </div>
                            <p class="small py-2">This is an in-depth proteomic characterization of skin- and blood-derived ILC2s and ILC3s and describes over 6 600 proteins</p>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">skin</div>
                        </router-link>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <h4 class="card-title mb-4">Skin Disease Atlas Datasets:</h4>
                <div class="card shadow">
                    <img class="card-img-top" style="object-fit: cover; width: 100%; max-height: 250px; overflow: hidden;"  src="images/Melanoma-dataset.jpg" alt="">
                    <ul class="list-group list-group-flush">
                        <router-link class="list-group-item list-group-item-action flex-column" to="/dataset/melanoma-vs-nevi">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Melanoma vs Nevi</h5>
                            </div>
                            <p class="small py-2">This is an in-debt proteomic characterization of microdissected melanoma cells and nevi cells within same patient specimen. We have quantified over 4400 proteins that include novel drivers in the malignant transformation of melanoma.</p>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">melanoma</div>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">DIA</div>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">proteome</div>
                            <div class="badge bg-pill rounded-pill mb-2" style="margin-right:2px;">skin</div>
                        </router-link>
                        <a href="#" class="list-group-item list-group-item-action flex-column">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Vitiligo</h5>
                            </div>
                            <p class="small py-2">Coming soon...</p>
                            <br/>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action flex-column">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Psoriasis</h5>
                            </div>
                            <p class="small py-2">Coming soon...</p>
                            <br/>
                        </a>
                    </ul>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    //import SubscribeForm from '@/components/SubscribeForm.vue';
    export default {
        name: 'HomeDatasets',
        components: { },
        methods: {
            fixedEncodeURIComponent(str) {
                return "/dataset/" + str.replace(/[^\w\s]/gi, '').replaceAll(" ", "-").toLowerCase().substring(0, 60);
            }
        },
        computed: {
            ...mapState({
                global: state => state.global
            })
        }
    }
</script>
<style>
</style>