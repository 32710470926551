<template>
    <div class="container">
        <div class="row py-3" id="dataset">
            <div class="row justify-content-between">
                <div class="col-8">
                    <h1>Dataset: {{dataset.name}}</h1>
                </div>
            </div>
        </div>

        <div v-if="!loaded" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-sm-9">
                <div class="py-3" id="description">
                    <Content url="decription" :param="dataset.id" title="Description" h="h3" />
                </div>
                <div class="py-3" id="method">
                    <Content url="method" :param="dataset.id" title="Method" h="h3" />
                </div>
                <div class="py-3" id="dataAnalysis">
                    <Content url="dataAnalysis" :param="dataset.id" title="Data analysis" h="h3" />
                    <!-- PCAPlot :param="dataset.id"></PCAPlot -->
                </div>
                <div class="py-3" id="pca">
                    <Content url="pca" :param="dataset.id" title="Principal component analysis" h="h3" />
                </div>
                <div v-if="volcanoData" class="py-3">
                    <VolcanoPlot :param="dataset.id" :volcanoData="volcanoData"></VolcanoPlot>
                </div>
                <div class="py-3" id="search">
                    <h3>Search dataset</h3>

                    <div>
                        <ProteinGraph :datasetId="dataset.id" query="0" />
                    </div>

                </div>
                <div class="py-3" id="download">
                </div>
            </div>
            <div class="col-sm-3">
                <div class="position-sticky" style="top: 2rem;">
                    <div id="list-example" class="list-group">
                        <a class="list-group-item list-group-item-action" :href=dataset.url target="_blank"><i class="bi bi-info-circle p-1" /> View publication</a>
                        <a class="list-group-item list-group-item-action" :href=dataset.pdf target="_blank"><i class="bi bi-file-earmark-pdf-fill p-1" /> Download publication</a>
                        <a class="list-group-item list-group-item-action" href="#" @click.prevent="download"><i class="bi bi-cloud-download p-1" /> Download data</a>
                    </div>
                    <h3 class="py-4">Sections:</h3>
                    <div id="list-example" class="list-group">
                        <a class="list-group-item list-group-item-action" href="#dataset">Dataset description</a>
                        <a class="list-group-item list-group-item-action" href="#method">Method</a>
                        <a class="list-group-item list-group-item-action" href="#dataAnalysis">Data analysis</a>
                        <a class="list-group-item list-group-item-action" href="#pca">Principal component analysis</a>
                        <a class="list-group-item list-group-item-action" href="#search">Search dataset</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import Content from '@/components/Content.vue';
    import ProteinGraph from '@/components/ProteinGraph.vue';
    import VolcanoPlot from "@/components/VolcanoPlot.vue";
    //import PCAPlot from "@/components/PCAPlot.vue";
    
    //import * as VolcanoData from '/public/volcano_plots.json';
    //import * as PCAData from '/public/pca_plots.json';

    export default {
        name: 'Dataset',
        components: { Content, ProteinGraph, VolcanoPlot }, //PCAPlot
        data() {
            return {
                dataset: {
                    name: 'Loading...'
                },
                isInEditMode: true,
                volcanoData: null,
                pcaData: null
            }
        },
        methods: {
            async getData() {
                //console.log("route: " + this.$route.params.url);

                await axios.get(process.env.VUE_APP_URL + `api/datasets/name/${this.$route.params.url}`)
                    .then(response => {
                        this.dataset = response.data;
                        this.loaded = true;
                        
                        fetch('../volcano_plots.json')
                            .then(response => response.json())
                            .then(json => {
                                //console.log(json);
                                this.volcanoData = json.filter(i => i.id == this.dataset.id)[0].files;
                                //console.log(this.volcanoData);
                            })
                            .catch((error) => console.log(error.message))                        
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });
            },
            
            download() {
                const url = '../' + this.$route.params.url + '-dataset.zip';
                window.location.href = url;
            }
        },
        beforeMount() {
            this.getData();
        },
        watch: {
            'isInEditMode': {
                handler: async function () {
                    //console.log("changed");
                },
                deep: true,
                immediate: true
            },
            '$route.params.protein': {
                handler: async function () {
                    if (this.$route.params.protein) this.getData();
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>
