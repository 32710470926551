const state = {
    datasets: null,
    skinStories: null,
    collaborators: null,
    allSkinStories: null
};

const actions = {
    saveDatasets({ commit }, datasets) {
        commit('save2Datasets',datasets);
    },
    saveSkinStories({ commit }, skinStories) {
        commit('save2SkinStories', skinStories);
    },
    saveAllSkinStories({ commit }, allSkinStories) {
        commit('save2AllSkinStories', allSkinStories);
    },
    saveCollaborators({ commit }, collaborators) {
        commit('save2Collaborators',collaborators);
    }
}

const mutations = {
    save2Datasets(state, payload) {
        console.log("saved datasets");
        state.datasets = payload;
    },
    save2SkinStories(state, payload) {
        console.log("saved skinstories");
        state.skinStories = payload;
    },
    save2AllSkinStories(state, payload) {
        console.log("saved all skinstories");
        state.allSkinStories = payload;
    },
    save2Collaborators(state, payload) {
        console.log("saved collaborators");
        state.collaborators = payload;
    }
}


export const global = {
    namespaced: true,
    state,
    actions,
    mutations,
};