<template>
    <div style="background:#13101c">
        <div class="container text-light">
            <div class="py-3">
                <b>Disclaimer</b> This resource is intended for research purposes only.
            </div>
        </div>
    </div>
    <footer class="py-5 bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <h5 class="text-light">Skin Science</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item mb-2"><router-link class="nav-link p-0" :to="{ name: 'Home' }">Home</router-link></li>
                        <li class="nav-item mb-2"><router-link class="nav-link p-0" :to="{ name: 'MassSpectrometry' }">Mass spectrometry</router-link></li>
                        <li class="nav-item mb-2"><router-link class="nav-link p-0" :to="{ name: 'SkinStories' }">Skin Stories</router-link></li>
                        <li class="nav-item mb-2"><router-link class="nav-link p-0" :to="{ name: 'Resources' }">Resources</router-link></li>
                    </ul>
                </div>

                <div class="col-sm-3">
                    <h5 class="text-light">&nbsp;</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item mb-2"><router-link class="nav-link p-0" :to="{ name: 'Contact' }">Contact</router-link></li>
                        <li class="nav-item mb-2"><router-link class="nav-link p-0" :to="{ name: 'About' }">About</router-link></li>
                        <li class="nav-item mb-2"><router-link class="nav-link p-0" :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link></li>
                    </ul>
                </div>

                <div class="col-md-3">
                    <h5 class="text-light">Datasets</h5>
                    <ul class="nav flex-column" v-for="item in global.datasets" :key="item.id">
                        <li class="nav-item mb-2"><router-link :to="{path:fixedEncodeURIComponent(item.name)}" class="nav-link p-0 text-muted">{{item.name}}</router-link></li>
                    </ul>
                </div>

                <div class="col-lg-3">
                    <form>
                        <h5 class="text-light">Subscribe to our newsletter</h5>
                        <p class="text-muted">Monthly digest of whats new and exciting from us.</p>
                        <SubscribeForm class="my-2"/>
                    </form>
                </div>
            </div>
            <div class="d-flex justify-content-between py-4 my-4 border-top text-white-50">
                <p></p>
                <ul class="list-unstyled d-flex">
                    <li class="ms-3"><a class="link-light" href="#"><i class="bi bi-linkedin"></i></a></li>
                    <li class="ms-3"><a class="link-light" href="https://twitter.com/skinproteomics" target="_blank"><i class="bi bi-twitter"></i></a></li>
                    <li class="ms-3"><a class="link-light" href="mailto:hello@skin.science"><i class="bi bi-envelope"></i></a></li>
                </ul>
            </div>
        </div>
    </footer>
</template>
<script>
    import { mapState } from 'vuex';
    import SubscribeForm from '@/components/SubscribeForm.vue';
    export default {
        name: 'Footer',
        components: { SubscribeForm },
        computed: {
            ...mapState({
                global: state => state.global
            })
        },
        methods: {
            fixedEncodeURIComponent(str) {
                return "/dataset/" + str.replace(/[^\w\s]/gi, '').replaceAll(" ", "-").toLowerCase().substring(0, 60);
            }
        }
    }
</script>
