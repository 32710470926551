<template>
    <div class="SearchProtein container py-5">

        <h1>Protein search: {{protein}}</h1>
        <div v-if="loadingProtein" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else>
            <div>UniProt name: <b>{{uniprotName}}</b></div>
            <div>UniProt id: <b>{{uniprotId}}</b></div>
            <div>Gene name: <b>{{uniprotGeneName}}</b></div>
            <div class="mt-1" v-if="uniprotAccession">
                <a :href="'https://www.uniprot.org/uniprot/' + uniprotAccession" target="_blank">https://www.uniprot.org/uniprot/{{uniprotAccession}}</a>
                <br />
                <a :href="'https://www.proteinatlas.org/' + proteinAtlasAccession" target="_blank">https://www.proteinatlas.org/{{proteinAtlasAccession}}</a>
            </div>
        </div>
        <div v-if="loading" class="d-flex justify-content-center m-5">
            <div class="spinner-border me-3 mt-1" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <h3>Loading dataset results</h3>
        </div>
        <div v-else class="py-5">
            <h1>Results in datasets:</h1>
            <div class="row justify-content-xl-start">
                <div class="col-md-4 mb-5" v-for="(results, index) in datasets" :key="index">
                    <div class="card shaddow">
                        <h5 class="card-header">{{results.dataset.name}}</h5>
                        <div v-if="results.chartData" class="card-body">
                            <div class="row justify-content-between pb-5">
                                <div class="col-12">
                                    <ChartLine :chartData=results?.chartData />
                                </div>
                            </div>
                            <router-link :to="{path:'/searchDataset', query:{dataset:results.dataset.id, protein:protein}}" class="btn btn-primary mt-3">View results</router-link>
                        </div>
                        <div v-else class="card-body">
                            <p>Protein {{protein}} was not found in dataset {{results.dataset.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="row justify-content-between pb-5">
        <div class="col-12">
            <ChartLine :chartData=results?.chartData />
        </div>
        </div>-->
    </div>
</template>



<script>
    import axios from 'axios';
    import ChartLine from '@/components/ChartLine.vue';
    import { toastOptions } from '@/helpers/toastOptions.js';
    import { searchService } from '@/services/searchService.js';



    export default {
        name: 'SearchProtein',
        components: { ChartLine },
        data() {
            return {
                query: '',
                protein: '',
                response: '',
                datasets: [],
                uniprotName: null,
                uniprotId: null,
                uniprotGeneName: null,
                uniprotAccession: null,
                proteinAtlasAccession: null,
                loading: true,
                loadingProtein: true
            }
        },
        methods:
        {
            async getProteins(query) {
                this.query = query;
                this.datasets = [];
                this.uniprot = {};

                await axios.get(process.env.VUE_APP_URL + 'api/datasets')
                    .then((response) => {
                        let chartData = [];
                        axios.get(process.env.VUE_APP_URL + 'api/search/datasets/:query'.replace(':query', query))
                            .then((r) => {
                                for (var i = 0; i < r.data.length; i++) {
                                    chartData.push({
                                        datasetId: r.data[i].datasetId,
                                        chartData: searchService.parseSearchData(r.data[i], "#1A8FE3")
                                    });
                                }
                                for (var y = 0; y < response.data.length; y++) {
                                        this.datasets.push({
                                            dataset: response.data[y],
                                            chartData: chartData?.find(c => c.datasetId === response.data[y].id)?.chartData
                                        });
                                }
                                this.loading = false;
                            }).catch(error => {
                                console.log(error);
                            });
                    })
                    .catch(error => {
                        this.$toast(error.message, toastOptions.error);
                    });

                await axios.get('https://www.ebi.ac.uk/proteins/api/proteins/:query'.replace(':query', query))
                    .then(async (r) => {

                        this.uniprotId = r.data.id;
                        this.uniprotName = r.data.protein?.recommendedName?.fullName?.value ??
                            r.data.protein?.submittedName[0]?.fullName?.value;
                        this.uniprotGeneName = r.data.gene[0]?.name?.value ?? "unavailable";
                        this.uniprotAccession = r.data.accession;

                        await axios.get('https://www.proteinatlas.org/api/search_download.php?search=:query&format=json&columns=g,gs&compress=no'.replace(':query', this.uniprotGeneName))
                            .then((r) => {
                                this.proteinAtlasAccession = r.data[0].Ensembl;
                            })
                            .catch((error) => {
                                console.log("protein atlas error: " + error.message);
                            });
                    })
                    .catch(() => {
                        this.uniprotId = "unavailable";
                        this.uniprotName = "unavailable";
                        this.uniprotGeneName = "unavailable";
                        this.uniprotAccession = null;
                    })

                this.loadingProtein = false;
            }
        },
        watch:
        {
            '$route.params.protein': {
                handler: async function () {
                    if (this.$route.query.protein) {
                        this.protein = this.$route.query.protein;
                        this.loadingProtein = true;
                        this.loading = true;
                        await this.getProteins(this.$route.query.protein);
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>