export const toastOptions =  {
    success: {
        duration: 5000,
        styles: {
            borderRadius: '25px',
            background: '#3e8d63'
        },
        class: 'local-class',
        type: 'error',
        positionX: 'center',
        positionY: 'top',
        disableClick: true
    },
    error: {
        duration: 5000,
        styles: {
            borderRadius: '25px',
            background: '#d62518'
        },
        class: 'local-class',
        type: 'success',
        positionX: 'center',
        positionY: 'top',
        disableClick: true
    },
    info: {
        duration: 0,
        styles: {
            borderRadius: '25px',
            background: '#ff9636'
        },
        class: 'local-class',
        type: 'success',
        positionX: 'center',
        positionY: 'top',
        disableClick: false,
        slotRight: '<i class="bi bi-x-circle-fill"></i>'
    }
};