<template>
    <div class="bg-light">
        <div class="container">
            <div class="raw pt-5 pb-5">
                <p>This work is supported by the Leo Foundation, the Novo Nordisk Foundation, the Lundbeck Foundation, the Aage Bangs Foundation and the A.P. M&#248;ller Foundation for the Advancement of Medical Sciences.</p>
                <p>We would like to thank the faculty of Health and Medical Sciences at the University of Copenhagen for continuous support.</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Credits'
    }
</script>