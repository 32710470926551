<script>

    import { defineComponent } from 'vue'
    import { Bar } from 'vue3-chart-v2'

    const options = {
        maintainAspectRatio: false,
        animation: {
            duration: 100,
            easing: 'easeInOutElastic'
        },
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Intensity'
                }
            }]    
        }
    };

    export default defineComponent({
        name: 'ChartLine',
        extends: Bar,
        props: {
            chartData: {
                type: Object
            }
        },
        methods: {
            renderLineChart() {
                this.renderChart(this.data, options);
            }
        },
        watch: {
            chartData: {
                deep: true, handler() {
                    if (this.chartData) {
                        this.state.chartObj.destroy()
                        this.renderLineChart();
                    }
                }
            }
        },
        mounted() {
            this.renderLineChart();
        },
        computed: {
            data: function () {
                return JSON.parse(JSON.stringify(this.chartData));
            }
        }
    })
</script>

