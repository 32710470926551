<template>
    <div class="container py-3">
        <h1>Collaborators</h1>
        <div class="row mb-5">
            <div v-if="!global.collaborators" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else class="col-lg-4 mb-2" v-for="item in global.collaborators" :key="item.id">
                <div class="card h-100 shadow border-0">
                    <div class="card-body p-4">
                        <div class="small">
                            <div class="fw-bold">{{item.name}}</div>
                            <div class="text-muted">{{item.department}}</div>
                            <div class="text-decoration-none">{{item.organization}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
        name: 'Collaborators',
        computed: {
            ...mapState({
                global: state => state.global
            })
        }
    }
</script>
