<template>
    <div>
        <h3>Volcano Plots</h3>
        
        <div class="row py-3">
            <label class="col-3 col-form-label">
                Select a volcano plot:
            </label>
            <div class="col-9">
                <select v-model="selectedVolcanoPlot" class="form-select py-2">
                    <option v-for="(item, index) in volcanoData" :key="index" :value="item.file" >{{item.name}}</option>
                </select>
            </div>
        </div>

        <div id="graph" ref="volcanoPlot" style="margin:30px 30px 0px 30px">
            <svg></svg>
        </div>
    </div>
</template>
  
<script>
import * as d3 from 'd3';

export default {
    name: 'VolcanoPlot',
    data(){
        return {
            selectedVolcanoPlot : null
        }
    },
    props: {
        param: { default: null },
        volcanoData: { default: null },
    },
    methods: {
        onChange(e){
            this.selectedVolcanoPlot = e.target.value;
        },
        loadVolcanoPlot(csvFile){
            const margin = { top: 30, right: 10, bottom: 50, left: 80 },
            width = this.$refs.volcanoPlot.clientWidth,
            height = width * 0.9;

            d3.select(this.$refs.volcanoPlot).select("svg").remove();
            
            const svg = d3.select(this.$refs.volcanoPlot).append('svg')             
                //.attr("width", width + margin.left + margin.right)
                //.attr("height", height + margin.top + margin.bottom)
                .attr("viewBox", `0 0 ${height + margin.top + margin.bottom} ${width + margin.left + margin.right}`)
                .append("g")
                .attr("transform", `translate(${margin.left}, ${margin.top})`);

            var tooltip = d3.select(this.$refs.volcanoPlot)
                .append("div")
                .style("position", "absolute")
                .style("z-index", "10")
                .style("visibility", "hidden")
                .style("background", "#000")
                .style("color", "#fff")
                .style("border-radius", "6px")
            
            var self = this;

            d3.csv(csvFile).then(
            function (data) {
                
                    const cutOffH = data[0].CutOffH;

                    const domainX = d3.max(data, function (d) { return Math.ceil(d.Log2FoldChange); });
                    const domainY = d3.max(data, function (d) { return Math.ceil(d.LOG10FDR); });

                    console.log("Domain X: " + domainX);
                    console.log("Domain Y: " + domainY);

                    const x = d3.scaleLinear()
                        .domain([-domainX, domainX])
                        .range([0, width - margin.left - margin.right]);

                    const y = d3.scaleLinear()
                        .domain([0, domainY])
                        .range([height, 0]);

                    svg.append("g")
                        .attr("transform", `translate(0, ${height})`)
                        .call(d3.axisBottom(x));

                    svg.append("g")
                        .call(d3.axisLeft(y));

                    svg.append("text")
                        .attr("text-anchor", "end")
                        .attr("x", width - margin.left - margin.right)
                        .attr("y", height + margin.top + 30)
                        .attr("fill", "#454545")
                        .text("log2 Fold Change");

                    svg.append("text")
                        .attr("text-anchor", "end")
                        .attr("transform", "rotate(-90)")
                        .attr("y", -margin.left + 15)
                        .attr("x", -margin.top)
                        .attr("fill", "#454545")
                        .text("-log10(FDR)")
                    // Add dots
                    svg.append('g')
                        .selectAll("dot")
                        .data(data)
                        .join("circle")
                        .attr("cx", function (d) { return x(d.Log2FoldChange); })
                        .attr("cy", function (d) { return y(d.LOG10FDR); })
                        .attr("r", 3)
                        .style("fill", function (d) {
                            if (d.Log2FoldChange > 1 && d.LOG10FDR > cutOffH) {
                                return "#ff6b6b";
                            }
                            else if (d.Log2FoldChange < -1 && d.LOG10FDR > cutOffH) {
                                return "#1A8FE3";
                            }
                            else {
                                return "#CECECE";
                            }
                        })
                        .on("mouseover", function (e, d) {
                            d3.select(this).style("cursor", "pointer");
                            return tooltip.style("visibility", "visible")
                                .html("<p style='text-align:left; padding:8px;margin:0px;'>Gene: " + d.GeneName + "<br/>Protein: " + d.UniProtID + "</p>")
                        })
                        .on("mousemove", function (e) {
                            return tooltip.style("top", (e.pageY - 40) + "px").style("left", (e.pageX + 5) + "px");
                        })
                        .on("mouseout", function () {
                            d3.select(this).style("cursor", "default");
                            return tooltip.style("visibility", "hidden")
                        })
                        .on("click", function(e, d) {
                            self.$router.push({ name: 'SearchProteinInDataset', query: { dataset: self.param, protein: d.UniProtID } })
                        });

                    svg.append('line')
                        .attr('x1', x(1))
                        .attr('x2', x(1))
                        .attr('y1', 0)
                        .attr('y2', height)
                        .attr("stroke", "#454545")
                        .attr("stroke-dasharray", "5,5")

                    svg.append('line')
                        .attr('x1', x(-1))
                        .attr('x2', x(-1))
                        .attr('y1', 0)
                        .attr('y2', height)
                        .attr("stroke", "#454545")
                        .attr("stroke-dasharray", "5,5")

                    svg.append('line')
                        .attr('x1', 0)
                        .attr('x2', width)
                        .attr('y1', y(cutOffH))
                        .attr('y2', y(cutOffH))
                        .attr("stroke", "#454545")
                        .attr("stroke-dasharray", "5,5")
            })
        }
    },
    mounted() {
        //if (this.volcanoData && this.volcanoData.files[0].file) {
        //    console.log("file: " + this.volcanoData.files[0].file);
        //    this.loadVolcanoPlot('../' + this.volcanoData.files[0].file);
        //}
        this.selectedVolcanoPlot = this.volcanoData[0].file
    },
    watch: {
        selectedVolcanoPlot(){
            this.loadVolcanoPlot('../' + this.selectedVolcanoPlot)
        },
        volcanoData(){
            if (this.volcanoData) {                
                    this.selectedVolcanoPlot = this.volcanoData[0].file;                
            }
        }
    },
    computed(){
    }
}

</script>
<style scoped>
    .tooltip {position:absolute;}
  </style>

  <!-- .join("a")
        .attr("xlink:href", function(d) {return "searchProtein?protein=" + d.UniProtID})
        .append("circle") 
    
        function linkClicked(d) {
            this.$router.push({ name: 'SearchProtein', query: { protein: d.UniProtID } })
        }
        :selected="index === 0"
    -->
  