<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark px-5 fixed">
        <router-link class="navbar-brand" to="/" style="font-family:Arvo; font-size:2em;">Skin Science</router-link>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarContent">
            <ul class="navbar-nav ms-auto">
                <li class="align-middle"><router-link class="nav-link px-3" :to="{ path : '/mass-spectrometry' }">Mass spectrometry</router-link></li>
                <li class="align-middle"><router-link class="nav-link px-3" :to="{ path : '/skin-stories' }">Skin Stories</router-link></li>
                <li class="align-middle"><router-link class="nav-link px-3" :to="{ path : '/resources' }">Resources</router-link></li>
                <li class="align-middle"><router-link class="nav-link px-3" :to="{ path : '/about' }">About</router-link></li>
                <li class="align-middle"><router-link class="nav-link px-3" :to="{ path : '/contact' }">Contact</router-link></li>
            </ul>
            <div class="navbar-header pull-left">
                <SearchAutocomplete />
            </div>
        </div>


    </nav>
</template>

<script>
    import SearchAutocomplete from '@/components/SearchAutocomplete.vue'

    export default {
        name: 'HeaderLinks',
        components: {
            SearchAutocomplete
        }
    }
</script>

<style>
    .navbar-nav {
        align-items: center;
        display: flex;
    }
</style>
