<template>
    <div class="container py-3">
        <h1>Page not found</h1>
        <p>Whoops. Something went wrong.</p>
    </div>
</template>
<script>
    export default {
        name: 'NotFound'
    }
</script>