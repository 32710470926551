<template>
    <div class="container py-5">
        <h1>Search in dataset: {{dataset.name}}</h1>

        <div v-if="loadingProtein" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else>
            <div>UniProt name: <b>{{uniprotName}}</b></div>
            <div>UniProt id: <b>{{uniprotId}}</b></div>
            <div>Gene name: <b>{{uniprotGeneName}}</b></div>
            <div class="mt-1" v-if="uniprotAccession">
                <a :href="'https://www.uniprot.org/uniprot/' + uniprotAccession" target="_blank">https://www.uniprot.org/uniprot/{{uniprotAccession}}</a>
                <br />
                <a :href="'https://www.proteinatlas.org/' + proteinAtlasAccession" target="_blank">https://www.proteinatlas.org/{{proteinAtlasAccession}}</a>
            </div>
        </div>

        <ProteinGraph :datasetId="$route.query.dataset" :query="$route.query.protein" />
    </div>
</template>
<script>
    import axios from "axios";
    import ProteinGraph from '@/components/ProteinGraph.vue';

    export default {
        name: 'SearchProteinInDataset',
        components: { ProteinGraph },
        data() {
            return {
                datasetParam: 0,
                proteinParam: '',
                dataset: {
                    id: 0,
                    name: '',
                    image: '',
                    shortDescription: '',
                    tags: [],
                    url: '',
                    pdf: ''
                },
                uniprotName: null,
                uniprotId: null,
                uniprotGeneName: null,
                uniprotAccession: null,
                proteinAtlasAccession: null,
                loading: true,
                loadingProtein: true
            }
        },
        methods:
        {
            async getDatasetInfo(datasetId, protein) {
                await axios.get(process.env.VUE_APP_URL + `api/datasets/${datasetId}`)
                    .then(response => {
                        this.dataset = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {

                    });

                    await axios.get('https://www.ebi.ac.uk/proteins/api/proteins/:query'.replace(':query', protein))
                    .then(async (r) => {

                        this.uniprotId = r.data.id;
                        this.uniprotName = r.data.protein?.recommendedName?.fullName?.value ??
                            r.data.protein?.submittedName[0]?.fullName?.value;
                        this.uniprotGeneName = r.data.gene[0]?.name?.value ?? "unavailable";
                        this.uniprotAccession = r.data.accession;

                        await axios.get('https://www.proteinatlas.org/api/search_download.php?search=:query&format=json&columns=g,gs&compress=no'.replace(':query', this.uniprotGeneName))
                            .then((r) => {
                                this.proteinAtlasAccession = r.data[0].Ensembl;
                            })
                            .catch((error) => {
                                console.log("protein atlas error: " + error.message);
                            });
                    })
                    .catch(() => {
                        this.uniprotId = "unavailable";
                        this.uniprotName = "unavailable";
                        this.uniprotGeneName = "unavailable";
                        this.uniprotAccession = null;
                    })

                this.loadingProtein = false;
            }
        },
        beforeMount() {
                this.datasetParam = this.$route.query.dataset;
                this.getDatasetInfo(this.$route.query.dataset, this.$route.query.protein);
        }
    }
</script>
