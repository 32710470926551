import { createStore } from 'vuex'
import { alert } from './alert.module';
import { account } from './account.module';
import { users } from './user.module';
import { global } from './global.module';

export default createStore({
    modules: {
        alert,
        account,
        users,
        global
    }
});

