<template>
    <div class="container py-3">
        <Content url="contact" title="Contact" h="h1"/>
    </div>
</template>
<script>
    import Content from '@/components/Content.vue';

    export default {
        name: 'Contact',
        components: { Content }
    }
</script>