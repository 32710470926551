import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import DKToast from 'vue-dk-toast'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap'

createApp(App)
    .use(router)
    .use(store)
    .use(DKToast)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
