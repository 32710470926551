<template>
    <div class="container py-3">
        <Content url="about" title="About" h="h1"/>
    </div>
</template>

<script>
    import Content from '@/components/Content.vue';

    export default {
        name: 'About',
        components: { Content }
    }
</script>