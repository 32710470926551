<template>
    <form class="form-inline">
        <SimpleTypeahead id="typeahead_id"
                         ref="autocomplete"
                         placeholder="Search protein"
                         :items="items"
                         :minInputLength="2"
                         :itemProjection="itemProjectionFunction"
                         @selectItem="onSelect"
                         @onInput="handleInput">
        </SimpleTypeahead>
    </form>
</template>
<script>
    import SimpleTypeahead from 'vue3-simple-typeahead'
    import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

    import axios from "axios";

    const ENDPOINT_SEARCH = process.env.VUE_APP_URL + 'api/search/protein/:query'
    const ENDPOINT_SEARCH_BY_DATASET = process.env.VUE_APP_URL + 'api/search/datasetprotein/:id/:query';

    export default {
        name: 'SearchAutocomplete',
        components: {
            SimpleTypeahead
        },
        props: ['dataset'],
        data() {
            return {
                itemProjectionFunction: function (item) { return item.name },
                items: []
            }
        },
        methods: {
            onSelect: function (selection) {
                if (this.dataset) {
                    //console.log("emitted: " + selection.name);
                    this.$emit('update:selection', selection.name);
                }
                else {
                    this.$router.push({ name: 'SearchProtein', query: { protein: selection.name } });
                }
                this.$refs.autocomplete.input = '';
            },
            handleInput: async function (e) {
                if (e.input.length > 1)
                if (this.dataset) {
                    await axios.get(ENDPOINT_SEARCH_BY_DATASET.replace(':query', e.input).replace(':id', this.dataset))
                        .then((response) => {
                            this.items = response.data;
                            if (e.input == "0") {
                                this.$emit('update:selection', response.data[0]);
                            }
                        })
                        .catch(() => {
                            console.log("Could not get autocomplete data");
                        });
                }
                else {
                    await axios.get(ENDPOINT_SEARCH.replace(':query', e.input))
                        .then((response) => {
                            this.items = response.data;
                        })
                        .catch(() => {
                            console.log("Could not get autocomplete data");
                        });
                }
            },
            clearSelection: function (data) {
                console.log(data);
                this.selection = null;

            }
        }
    }
</script>