<template>
    <div class="container py-3">
        <Content url="mass-spectrometry" title="Mass spectrometry" h="h1" />
    </div>
</template>
<script>
    import Content from '@/components/Content.vue';

    export default {
        name: 'MassSpectrometry',
        components: { Content }
    }
</script>