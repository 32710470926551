export const searchService = {
    parseSearchData: function (result, color) {
        let labels = [];
        let values = [];
        let colors = [];

        const reduced = reduceData(result);
        const averaged = averageData(reduced);

        averaged.forEach(item => {
            labels.push(item.l);
            values.push(item.v);
            colors.push(addAlpha(color, item.c));
        });

        return {
            labels: labels,
            datasets: [{ data: values, label: result.protein, backgroundColor: colors, borderColor: colors, borderWidth: 1 }]
        };
    },
    createBase: function (result) {
        let labels = [];

        const reduced = reduceData(result);
        const averaged = averageData(reduced);

        averaged.forEach(item => {
            labels.push(item.l);
        });

        return {
            labels: labels,
            datasets: []
        };
    },
    parseDataset: function (result, color) {
        let values = [];
        let colors = [];

        const reduced = reduceData(result);
        const averaged = averageData(reduced);

        averaged.forEach(item => {
            values.push(item.v);
            colors.push(addAlpha(color, item.c));
        });

        return { data: values,
                    label: result.protein,
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1 };
    }
}

function addAlpha(color, opacity) {
    const c = color + (opacity == 1 ? '90' : 'FF');
    return c;
}

function reduceData(result) {
    return result.data.reduce(function (m, d) {
        if (!m[d.l]) {
            m[d.l] = { ...d, count: 1 };
            return m;
        }
        m[d.l].v += d.v
        m[d.l].count += 1;

        return m;
    }, {});
}

function averageData(reduced) {
    return Object.keys(reduced).map(function (k) {
        const item = reduced[k];
        return {
            l: item.l,
            v: item.v / item.count,
            c: item.c
        }
    });
}
