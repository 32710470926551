<template>
    <div class="container py-3">
        <Content url="resources" title="Resources"  h="h1"/>
    </div>
</template>
<script>
    import Content from '@/components/Content.vue';

    export default {
        name: 'Resources',
        components: { Content }
    }
</script>