<template>
    <div class="form-check form-switch">
        <input class="form-check-input"
               type="checkbox"
               id="flexSwitchCheckDefault"
               :checked=checked
               @input="(e) => onUpdate(e)">
        <label class="form-check-label"
               for="flexSwitchCheckDefault">{{label}}</label>
    </div>
</template>
<script>
    export default {
        name: 'CheckBox',
        props: {
            label: String,
            checked: {
                type: Boolean,
            }
        },
        methods:
        {
            onUpdate(event) {
                this.$emit('update:checked', event.target.checked);
            }
        }
    }
</script>