<template>
    <div class="row" v-if="account.user || (loaded && content.data.length > 10)">
        <div class="d-flex justify-content-between">
            <div>
                <h1 v-if="h == 'h1'">{{title}}</h1>
                <h1 v-if="h == 'h2'">{{title}}</h1>
                <h3 v-if="h == 'h3'">{{title}}</h3>
            </div>
            <div>
                <CheckBox v-if="account.user" label="Edit Mode"
                          :checked="isInEditMode"
                          @update:checked="isInEditMode = $event" />
            </div>
        </div>
    </div>
    <div v-if="!loaded" class="row d-md-flex justify-content-md-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else class="row">
        <div class="col-lg-12" v-if="isInEditMode">
            <form @submit.prevent="handleSubmit">
                <Editor :modelValue=content.data @update:modelValue="updateContent($event)" />
                <div v-if="isDirty" class="d-flex flex-row-reverse py-3">
                    <button class="btn btn-success">Save</button>
                </div>
            </form>
        </div>
        <div class="col-lg-12" v-else v-html="content.data" />
    </div>
</template>
<script>
    import CheckBox from '@/components/CheckBox.vue';
    import Editor from '@/components/Editor.vue';
    import axios from "axios";
    import { authHeader } from '@/services/authHeader.js';
    import { toastOptions } from '@/helpers/toastOptions.js';
    import { mapState } from 'vuex'

    export default {
        name: "Content",
        components: { CheckBox, Editor },
        computed: {
            ...mapState({
                account: state => state.account
            })
        },
        props: {
            title: String,
            url: String,
            h: String,
            param: { default: null }
        },
        data() {
            return {
                isInEditMode: false,
                isDirty: false,
                content: {
                    name: this.title,
                    url: this.param != null ? this.url + this.param : this.url,
                    data: ''
                },
                loaded: false
            }
        },
        methods: {
            getData() {
                axios.get(process.env.VUE_APP_URL + `api/contents/${this.param != null ? this.url + this.param : this.url}`)
                    .then(response => {
                        this.content = response.data;
                        if (this.param) this.content.url = this.url + this.param;
                    })
                    .catch(() => {
                        this.$toast("No content defined for section: " + this.title, toastOptions.error);
                    })
                    .finally(() => {
                        this.loaded = true;
                    });
            },
            handleSubmit() {
                this.loading = true;
                const options = {
                    headers: { ...authHeader(), 'Content-Type': 'application/json' }
                }

                if (!this.content.id) {
                    axios.post(process.env.VUE_APP_URL + 'api/contents', this.content, options)
                        .then((response) => {
                            this.$toast("save successful", toastOptions.success);
                            this.content = response.data;
                            this.isInEditMode = false;
                            this.isDirty = false;
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$toast(error.message, toastOptions.error);
                        });
                }
                else {
                    axios.put(process.env.VUE_APP_URL + `api/contents/${this.content.id}`, this.content, options)
                        .then(() => {
                            this.$toast("save successful", toastOptions.success);
                            this.isInEditMode = false;
                            this.isDirty = false;
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$toast(error.message, toastOptions.error);
                        });
                }
            },
            handlePageLeave(next) {
                if (this.isDirty) {
                    const answer = window.confirm(`Do you really want to leave? The ${this.title} section has unsaved changes!`)
                    if (answer) {
                        next()
                    } else {
                        next(false)
                    }
                }
            },
            updateContent(data) {
                if (this.content.data != data) {
                    this.isDirty = true;
                    this.content.data = data;
                }
            }
        },
        beforeMount() {
            this.getData();
        },
        beforeUnmount() {
            document.removeEventListener("beforeunload", this.handlePageLeave);
        },
        created() {
            window.addEventListener('beforeunload', this.handlePageLeave)
        },
        beforeRouteLeave(to, from, next) {
            this.handlePageLeave(next);
        }
    }
</script>
