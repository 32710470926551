<template>
    <div class="container">
        <div class="row my-3">
            <div class="col-sm-3 order-md-last">
                <div class="d-flex justify-content-between align-items-center">
                    Add protein to chart:
                </div>
                <SearchAutocomplete :dataset="datasetParam" @update:selection="addProtein($event, datasetParam)"/>

                <div class="d-flex justify-content-between align-items-center mb-3 mt-3">
                    <span>Proteins:</span>
                    <span class="badge bg-dark rounded-pill">{{proteins.length}}</span>
                </div>
                <ul class="list-group mb-3">
                    <li v-for="item in proteins" :key="item.id" class="list-group-item d-flex justify-content-between lh-sm h-100">
                        <div :style="'width: 20px; height: 20px; padding-left:20px; text-align:center; background-color:' + item.color"><div style="padding-left:10px;">{{item.name}}</div></div>
                        <button type="button" @click="removeProtein(item)" :disabled="item.id==0" class="btn-close align-middle" aria-label="Close"></button>
                    </li>
                </ul>
                <div class="list-group">
                    <a class="list-group-item list-group-item-action" href="#" target="_blank" @click.prevent="download"><i class="bi bi-cloud-download p-1" /> Download</a>
                </div>
            </div>
            <div class="col-sm-9">
                <h5 class="mb-3">Protein distribution:</h5>
                <ChartLine v-if="chartData" :chartData=chartData />                
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import ChartLine from '@/components/ChartLine.vue';
    import { searchService } from '@/services/searchService.js';
    import SearchAutocomplete from '@/components/SearchAutocomplete.vue'
    import download from 'downloadjs'

    function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
            return ele != value;
        });
    }

    export default {
        name: 'ProteinGraph',
        components: { ChartLine, SearchAutocomplete },
        props: ['query', 'datasetId'],
        data() {
            return {
                chartData: null,
                colors :[
                    '#1A8FE3',
                    '#ff6b6b',
                    '#00a878',
                    '#f4d06f',
                    '#60e1e0',
                    '#aa4465',
                    '#9CEC5B',
                    '#ff8811',
                    '#735CDD',
                    '#AFD0BF',
                    '#383d3b',
                    '#93e1d8',
                    '#ffa69e',
                    '#462255',
                    '#817f75',
                    '#d8d2e1',
                    '#FF6F59',
                    '#E2D58B',
                    '#F4B266',
                    '#816c61'
                ],
                colorIndex: 0,
                proteins: [],
                datasetParam: 0,
                dataset: {},
                downloadData: []
            }
        },
        methods:
        {
            async getProteins(query, id) {
                await axios.get(process.env.VUE_APP_URL + 'api/search/datasets/:id/:query'.replace(':query', query).replace(':id', id))
                    .then((r) => {
                        //console.log(r.data);
                        let base = searchService.createBase(r.data);

                        this.downloadData.push(r.data);

                        let proteinData = {
                            id: this.colorIndex,
                            name: r.data.protein,
                            data: searchService.parseDataset(r.data, this.colors[this.colorIndex]),
                            color: this.colors[this.colorIndex]
                        }
                        this.proteins.push(proteinData);
                        base.datasets.push(proteinData.data);
                        this.chartData = base;
                        this.colorIndex++;
                    }).catch(error => {
                        console.log(error);
                    });
            },

            async addProtein(query, id) {
                await axios.get(process.env.VUE_APP_URL + 'api/search/datasets/:id/:query'.replace(':query', query).replace(':id', id))
                    .then((rs) => {

                        this.downloadData.push(rs.data);

                        let color = this.colorIndex++;
                        let proteinData = {
                            name: rs.data.protein,
                            data: searchService.parseDataset(rs.data, this.colors[color]),
                            color: this.colors[color]
                        }
                        this.proteins.push(proteinData);
                        this.chartData.datasets.push(proteinData.data);

                    }).catch(error => {
                        console.log(error);
                    });
            },

            async getProteinData(query, id) {
                await axios.get(process.env.VUE_APP_URL + 'api/search/datasets/:id/:query'.replace(':query', query).replace(':id', id))
                    .then((r) => {
                        return r.data;
                    }).catch(error => {
                        console.log(error);
                        return null;
                    });
            },

            removeProtein(item) {
                if (this.proteins.length != 1) {
                    this.chartData.datasets = arrayRemove(this.chartData.datasets, item.data);
                    this.proteins = arrayRemove(this.proteins, item);
                    let i = this.downloadData.find(p=>p.protein == item.data.protein);
                    this.downloadData = arrayRemove(this.downloadData, i);
                }
            },

            download(){
                let d = [];
                this.downloadData.forEach(item => {
                    let dist = [];
                    item.data.forEach(d => {
                        if (d.s) {
                            dist.push({ layer: d.l, sample: d.s, value: d.v });
                        }
                        else {
                            dist.push({ layer: d.l, value: d.v });
                        }
                    });
                    d.push({ protein: item.protein, gene: item.gene, distributions: dist })
                });

                download(JSON.stringify(d), 'graph.json', 'application/json' );
            }
        },
        beforeMount() {
            if (this.query && this.datasetId) {
                this.datasetParam = this.datasetId;
                this.getProteins(this.query, this.datasetId);

            } else if (this.$route.query.protein && this.$route.query.dataset) {
                this.datasetParam = this.$route.query.dataset;
                this.getProteins(this.$route.query.protein, this.$route.query.dataset);
            }
        }
    }
</script>
<style>
    .simple-typeahead-input {
        width: 100%;
    }
</style>