<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-black px-5 fixed">
        <ul class="navbar-nav ml-auto">
            <li class="align-middle"><router-link class="nav-link" to="/dashboard">Dashboard</router-link></li>
            <li class="align-middle"><router-link class="nav-link" to="/admindatasets">Datasets</router-link></li>
            <li class="align-middle"><router-link class="nav-link" to="/adminskinstories">Skin stories</router-link></li>
            <li class="align-middle"><router-link class="nav-link" to="/admincollaborators">Collaborators</router-link></li>
            <li class="align-middle"><router-link class="nav-link" to="/imagemanager">Image Manager</router-link></li>
        </ul>
        <ul class="navbar-nav ms-auto">
            <li class="align-middle"><router-link class="nav-link" to="login">Logout</router-link></li>
        </ul>
    </nav>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        name: 'NavBarAdmin',
        mounted() {
            // reset login status
            this.clearAlert();
        },
        methods: {
            ...mapActions({
                clearAlert: 'alert/clear'
            })
        }
    }
</script>
