<template>
    <div id="app" class="d-flex flex-column" style="min-height: 100vh;">
        <NavBarCookies />
        <NavBarAdmin v-if="account.user" />
        <NavBar />
        <div class="flex-grow-1">
            <router-view :key="$route.url"/>
        </div>
        <Credits v-if="!account.user" />
        <Footer />
    </div>
</template>

<script>
    import NavBar from '@/components/NavBar.vue';
    import NavBarCookies from '@/components/NavBarCookies.vue';
    import Footer from '@/components/Footer.vue';
    import Credits from '@/components/Credits.vue';
    import NavBarAdmin from '@/components/NavBarAdmin.vue';

    import { toastOptions } from '@/helpers/toastOptions.js';
    import { mapState, mapActions } from 'vuex'
    import axios from "axios";

    export default {
        name: 'App',
        components: { NavBar, NavBarAdmin, Footer, Credits, NavBarCookies },

        computed: {
            ...mapState({
                alert: state => state.alert,
                account: state => state.account,
                global: state => state.global
            })
        },
        methods: {
            ...mapActions('global', ['saveDatasets', 'saveCollaborators', 'saveSkinStories']),
            getData() {
                this.getDataFor(this.global.datasets, "api/datasets", this.saveDatasets);
                this.getDataFor(this.global.collaborators, "api/collaborators", this.saveCollaborators);
                this.getDataFor(this.global.skinStories, "api/skinstories/page/0", this.saveSkinStories);
            },
            getDataFor(globalDataVariable, apiEndpoint, saveGlobadDataFunction)
            {
                if (!globalDataVariable) {
                    axios.get(process.env.VUE_APP_URL + apiEndpoint)
                        .then(response => {
                            //console.log("got response from " + apiEndpoint);
                            //console.log(response.data);
                            saveGlobadDataFunction(response.data);
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        })
                }
            }
        },
        beforeMount() {
            //console.log(process.env.VUE_APP_URL);
            this.getData();
        }
    }
</script>

<style>
    

    #app {
        font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @import'~bootstrap/dist/css/bootstrap.min.css';

    a.router-link-exact-active {
        color: #fff;
        text-decoration: none;
        text-decoration-color: #fff;
    }

    a.router-link-active {
        color: #fff;
        text-decoration:none;
        text-decoration-color: #fff;
    }

    a { color: #60a3d9 }

    .navbar-dark .navbar-nav .nav-link:hover {
        color: #fff;
        text-decoration: none;
    }

    .navbar-dark .navbar-nav .nav-link {
        text-decoration: none;
    }

    .navbar-dark .navbar-nav .nav-link:focus {
        color: #fff;
        text-decoration:none;
    }

    .navbar-dark .navbar-nav .nav-link .router-link-exact-active {
        color: #fff;
        text-decoration: none;
    }

    .nav-link {
        color: #6c757d;
    }

    .router-link-active .router-link-exact-active .nav-link {
        color: #fff;
    }

    .nav-link:hover, .nav-link:focus {
        color: #fff;
    }

    h1, h2, h3, h4, h5 {
        font-family: Arvo;
        font-weight: bolder;
        padding-top: .2em;
        padding-bottom: .2em;
    }

    .image-small {
        max-width: 30%;
    }

    .image-medium {
        max-width: 70%;
    }

    .image-large {
        max-width: 100%;
    }

    .image-float-none {
        float: none;
    }

    .image-float-left {
        float: left;
    }

    .image-float-right {
        float: right;
    }

    table {
        border-collapse: collapse;
        border:0px;
        table-layout: fixed;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: hidden;
    }

    td, th {
        min-width: 1em;
        border: 0px solid white;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
    }

    th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
    }

    .btn-primary {
        color: #fff;
        background-color: #1a2332;
        border-color: #1C3ABF    
    }

    .btn-danger {
    color: #1a2332;
    background-color: #d62518;
    border-color: #d62518
    }

    .btn-primary:hover {
        color: #1a2332;
        background-color: #60a3d9;
        border-color: #60a3d9
    }

    .bg-pill
    {
        background-color: #1a2332;
    }

</style>
