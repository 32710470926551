<template>
    <div class="content">
        <div class="row m-3">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                <form @submit.prevent="handleSubmit">
                    <input ref="imageUpload" type="file" name="file-upload"
                           accept="image/jpeg, image/png" @change="onUploadFiles" style="visibility:hidden" />
                </form>
                <button type="button" class="btn btn-danger">Delete image</button>
                <button type="button" class="btn btn-primary me-md-2" @click.stop="selectFiles()">Upload image</button>
            </div>
            <hr />
            <div v-for="(image, index) in images" :key="index" class="imgPlaceholder float-start m-1"
                 @click="onSelectImage(image)" :class="{ 'selected': image == selectedImage }">
                <img :src="'../images/' + image" style="object-fit: scale-down; width: 190px; height: 190px; margin:2px; padding:0px;" alt="">
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import { authHeader } from '@/services/authHeader.js';
    import { toastOptions } from '@/helpers/toastOptions.js';

    export default {
        name: 'ImageManager',
        data() {
            return {
                images: [],
                selectedImage: null
            }
        },
        methods: {
            getData() {
                axios.get(process.env.VUE_APP_URL + `api/imagemanager`)
                    .then(response => {
                        this.images = response.data;
                    })
                    .catch((error) => {
                        this.$toast("Error getting images: " + error.message, toastOptions.error);
                    })
                    .finally(() => {
                        this.loaded = true;
                    });
            },
            onSelectImage(objectImage) {
                //console.log(this.selectedImage + " " + objectImage);
                if (objectImage == this.selectedImage) {
                    this.selectedImage = null;
                }
                else {
                    this.selectedImage = objectImage;
                    this.$emit('update:selectedImage', objectImage);
                }
            },
            selectFiles() {
                //console.log("click");
                this.$refs.imageUpload.click();
            },
            onUploadFiles(event) {
                if (event.target.files[0]) {
                    const formData = new FormData();
                    const options = {
                        headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' }
                    }
                    formData.append('bytes', event.target.files[0]);
                    axios.post(process.env.VUE_APP_URL + 'api/imageManager', formData, options)
                        .then(data => {
                            //console.log(data.data);
                            this.images.push(data.data);
                            this.getData();
                        })
                        .catch(e => console.log(e))
                }
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>
<style>
    .imgPlaceholder {
        width: 200px;
        height: 200px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        border: 2px solid silver;
        margin: 0px;
        padding: 0px;
        cursor: pointer;
        padding:2px;
    }

    .selected {
        border: 4px solid green;
        margin: 0px;
        padding: 0px;
    }
</style>