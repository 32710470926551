<template>
    <div class="btn-toolbar py-2" id="editor" role="toolbar" aria-label="Toolbar with button groups" v-if="editor">
        <div class="btn-group me-2 mb-2" role="group">
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().undo().run()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Undo">
                <font-awesome-icon icon="undo" />
            </button>
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().redo().run()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Redo">
                <font-awesome-icon icon="redo" />
            </button>
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <button type="button"
                    @click="editor.chain().focus().toggleBold().run()"
                    class="btn btn-outline-dark" :class="{ 'active': editor.isActive('bold') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Bold">
                <font-awesome-icon icon="bold" />
            </button>
            <button type="button"
                    @click="editor.chain().focus().toggleItalic().run()"
                    class="btn btn-outline-dark" :class="{ 'active': editor.isActive('italic') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Italic">
                <font-awesome-icon icon="italic" />
            </button>
            <button type="button"
                    @click="editor.chain().focus().toggleStrike().run()"
                    class="btn btn-outline-dark" :class="{ 'active': editor.isActive('strike') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Striketrough">
                <font-awesome-icon icon="strikethrough" />
            </button>
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <!--<button type="button" @click="editor.chain().focus().unsetAllMarks().run()" class="btn btn-outline-dark">
            <font-awesome-icon icon="broom" />
        </button>-->
            <button type="button"
                    @click="editor.chain().focus().toggleCode().run()"
                    class="btn btn-outline-dark" :class="{ 'active': editor.isActive('code') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Format code">
                <font-awesome-icon icon="code" />
            </button>
            <button type="button"
                    @click="editor.chain().focus().clearNodes().run()"
                    class="btn btn-outline-dark"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Remove formatting">
                <font-awesome-icon icon="broom" />
            </button>
        </div>

        <!--<div class="btn-group me-2 mb-2" role="group">
        <button type="button" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="btn btn-outline-dark" :class="{ 'active': editor.isActive('heading', { level: 1 }) }">
            <i class="bi bi-type-h1"></i>
        </button>
        <button type="button" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="btn btn-outline-dark" :class="{ 'active': editor.isActive('heading', { level: 2 }) }">
            <i class="bi bi-type-h2"></i>
        </button>
        <button type="button" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" class="btn btn-outline-dark" :class="{ 'active': editor.isActive('heading', { level: 3 }) }">
            <i class="bi bi-type-h3"></i>
        </button>
    </div>-->

        <div class="btn-group me-2 mb-2" role="group">
            <div class="btn-group">
                <button type="button"
                        class="btn btn-danger dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <font-awesome-icon icon="heading" />
                </button>
                <ul class="dropdown-menu">
                    <li>
                        <a @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'active': editor.isActive('heading', { level: 1 }) }" class="dropdown-item" href="#">
                            H1
                        </a>
                    </li>
                    <li>
                        <a @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'active': editor.isActive('heading', { level: 2 }) }" class="dropdown-item" href="#">
                            H2
                        </a>
                    </li>
                    <li>
                        <a @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'active': editor.isActive('heading', { level: 3 }) }" class="dropdown-item" href="#">
                            H3
                        </a>
                    </li>
                    <li>
                        <a @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'active': editor.isActive('heading', { level: 4 }) }" class="dropdown-item" href="#">
                            H4
                        </a>
                    </li>
                    <li>
                        <a @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'active': editor.isActive('heading', { level: 5 }) }" class="dropdown-item" href="#">
                            H5
                        </a>
                    </li>
                    <li>
                        <a @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'active': editor.isActive('heading', { level: 6 }) }" class="dropdown-item" href="#">
                            H6
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <button type="button"
                    @click="editor.chain().focus().setParagraph().run()" class="btn btn-outline-dark" :class="{ 'active': editor.isActive('paragraph') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Paragraph">
                <font-awesome-icon icon="paragraph" />
            </button>
            <button type="button"
                    @click="editor.chain().focus().toggleBulletList().run()" class="btn btn-outline-dark" :class="{ 'active': editor.isActive('bulletList') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Unordered list">
                <font-awesome-icon icon="list-ul" />
            </button>
            <button type="button"
                    @click="editor.chain().focus().toggleOrderedList().run()" class="btn btn-outline-dark" :class="{ 'active': editor.isActive('orderedList') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Ordered list">
                <font-awesome-icon icon="list-ol" />
            </button>
            <button type="button"
                    @click="editor.chain().focus().toggleCodeBlock().run()" class="btn btn-outline-dark" :class="{ 'active': editor.isActive('codeBlock') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Unformatted">
                <font-awesome-icon icon="remove-format" />
            </button>
            <!--<button type="button"
                @click="editor.chain().focus().toggleBlockquote().run()" class="btn btn-outline-dark" :class="{ 'active': editor.isActive('blockquote') }"
                data-bs-toggle="tooltip" data-bs-placement="top" title="Paragraph">
            <i class="bi bi-blockquote-left"></i>
        </button>-->
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
                <font-awesome-icon icon="align-left" />
            </button>
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
                <font-awesome-icon icon="align-center" />
            </button>
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
                <font-awesome-icon icon="align-right" />
            </button>
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
                <font-awesome-icon icon="align-justify" />
            </button>
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().setHorizontalRule().run()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Horizontal rule">
                <font-awesome-icon icon="minus" />
            </button>
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().setHardBreak().run()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Hard break">
                <font-awesome-icon icon="grip-lines" />
            </button>
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Insert table">
                <font-awesome-icon icon="table" />
            </button>
            <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu">
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().addColumnBefore().run()" :class="{ 'is-active': !editor.can().addColumnBefore() }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Add column before">
                    Add Column Before
                </li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().addColumnAfter().run()" :disabled="!editor.can().addColumnAfter()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Add column after">
                    Add Column After
                </li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().deleteColumn().run()" :disabled="!editor.can().deleteColumn()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete column">
                    Delete Column
                </li>
                <li><hr class="dropdown-divider"></li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().addRowBefore().run()" :disabled="!editor.can().addRowBefore()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Add row before">
                    Add Row Before
                </li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().addRowAfter().run()" :disabled="!editor.can().addRowAfter()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Add row after">
                    Add Row After
                </li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().deleteRow().run()" :disabled="!editor.can().deleteRow()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete row">
                    Delete Row
                </li>
                <li><hr class="dropdown-divider"></li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().deleteTable().run()" :disabled="!editor.can().deleteTable()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete table">
                    Delete Table
                </li>
                <li><hr class="dropdown-divider"></li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().mergeCells().run()" :disabled="!editor.can().mergeCells()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Merge cells">
                    Merge Cells
                </li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().splitCell().run()" :disabled="!editor.can().splitCell()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Split cells">
                    Split Cell
                </li>
                <li><hr class="dropdown-divider"></li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().toggleHeaderColumn().run()" :disabled="!editor.can().toggleHeaderColumn()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Toggle heade row">
                    Toggle Header Column
                </li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().toggleHeaderRow().run()" :disabled="!editor.can().toggleHeaderRow()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Split cells">
                    Toggle Header Row
                </li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().toggleHeaderCell().run()" :disabled="!editor.can().toggleHeaderCell()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Toggle heder cell">
                    Toggle Header Cell
                </li>
                <li><hr class="dropdown-divider"></li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().mergeOrSplit().run()" :disabled="!editor.can().mergeOrSplit()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Merge or spllit">
                    Merge Or Split
                </li>
                <!--<li @click="editor.chain().focus().setCellAttribute('backgroundColor', '#FAF594').run()" :disabled="!editor.can().setCellAttribute('backgroundColor', '#FAF594')">
                setCellAttribute
            </li>-->
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().fixTables().run()" :disabled="!editor.can().fixTables()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Fix tables">
                    Fix Tables
                </li>
                <li><hr class="dropdown-divider"></li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().goToNextCell().run()" :disabled="!editor.can().goToNextCell()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Go to next cell">
                    Go To Next Cell
                </li>
                <li type="button" class="dropdown-item"
                    @click="editor.chain().focus().goToPreviousCell().run()" :disabled="!editor.can().goToPreviousCell()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Go to previous cell">
                    Go To Previous Cell
                </li>
            </ul>
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().toggleSuperscript().run()" :class="{ 'is-active': editor.isActive('superscript') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Toggle Superscript">
                <font-awesome-icon icon="superscript" />
            </button>
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().toggleSubscript().run()" :class="{ 'is-active': editor.isActive('subscript') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Toggle Subscript">
                <font-awesome-icon icon="subscript" />
            </button>
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <button type="button" class="btn btn-outline-dark"
                    @click="setLink" :class="{ 'is-active': editor.isActive('link') }"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Create link">
                <font-awesome-icon icon="link" />
            </button>
            <button type="button" class="btn btn-outline-dark"
                    @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Hard break">
                <font-awesome-icon icon="unlink" />
            </button>
        </div>

        <div class="btn-group me-2 mb-2" role="group">
            <button type="button" class="btn btn-outline-dark"
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                <font-awesome-icon icon="image" />
            </button>
            <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu">
                <li type="button" class="dropdown-item"
                    @onClick="editor.chain().focus().setTextAlign('left').run()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Left">
                    Float Left
                </li>
                <li type="button" class="dropdown-item"
                    @onClick="editor.chain().focus().setTextAlign('none').run()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="None">
                    Float None
                </li>
                <li type="button" class="dropdown-item"
                    @onClick="editor.chain().focus().setTextAlign('right').run()"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Right">
                    Float Right
                </li>
            </ul>
        </div>
    </div>
    <div style="border:1px solid silver">

        <!--<bubble-menu class="bubble-menu"
                     :tippy-options="{ animation: false }"
                     :editor="editor"
                     v-if="editor"
                     v-show="editor.isActive('image')">
            <button type="button" class="btn btn-outline-dark"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Create link">
                <font-awesome-icon icon="link" />
            </button>

            <div class="btn-group me-2 mb-2" role="group">
                <button type="button"
                        @onClick="editor.chain().focus().setTextAlign('left').run()">Left</button>
                <button type="button" @click="
                    editor
                        .chain()
                        .focus()
                        .setImage({ float: 'none' })
                        .run()
                "
                        :class="{
                    'is-active': editor.isActive('image', {
                        float: 'none'
                    })
                }">
                    No float
                </button>
                <button type="button" @click="
                    editor
                        .chain()
                        .focus()
                        .setImage({ float: 'right' })
                        .run()
                "
                        :class="{
                    'is-active': editor.isActive('image', {
                        float: 'right'
                    })
                }">
                    Right
                </button>
            </div>
        </bubble-menu>-->

        <editor-content class="p-0" :editor="editor" />
    </div>


    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Insert image</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ImageManager :selectedImage="selectedImage" @update:selectedImage="selectedImage = $event"/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="setImage(selectedImage)">Select image</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageManager from "@/components/ImageManager.vue";
    import { Editor, EditorContent  } from '@tiptap/vue-3';
    import StarterKit from '@tiptap/starter-kit';
    import TextAlign from '@tiptap/extension-text-align';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import Table from '@tiptap/extension-table';
    import TableRow from '@tiptap/extension-table-row';
    import TableCell from '@tiptap/extension-table-cell';
    import TableHeader from '@tiptap/extension-table-header';
    import Link from '@tiptap/extension-link';
    import Image from '@tiptap/extension-image';
    import Superscript from '@tiptap/extension-superscript'
    import Subscript from '@tiptap/extension-subscript'

    import {
        faUndo, faRedo, faBold, faItalic, faStrikethrough, faCode, faBroom, faHeading, faParagraph, faListUl,
        faListOl, faGripLines, faMinus, faRemoveFormat, faAlignLeft, faAlignRight, faAlignJustify, faAlignCenter,
        faTable, faImage, faLink, faUnlink, faSubscript, faSuperscript
    } from '@fortawesome/free-solid-svg-icons';
    library.add(
        faUndo, faRedo, faBold, faItalic, faStrikethrough, faCode, faBroom, faHeading, faParagraph, faListUl,
        faListOl, faGripLines, faMinus, faRemoveFormat, faAlignLeft, faAlignRight, faAlignJustify, faAlignCenter,
        faTable, faImage, faLink, faUnlink, faSubscript, faSuperscript
        );

    import "bootstrap/dist/js/bootstrap.min.js";
    import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';

    //const CustomTableCell = TableCell.extend({
    //    addAttributes() {
    //        return {
    //            // extend the existing attributes …
    //            ...this.parent?.(),

    //            // and add a new one …
    //            backgroundColor: {
    //                default: null,
    //                parseHTML: element => element.getAttribute('data-background-color'),
    //                renderHTML: attributes => {
    //                    return {
    //                        'data-background-color': attributes.backgroundColor,
    //                        style: `background-color: ${attributes.backgroundColor}`,
    //                    }
    //                },
    //            },
    //        }
    //    },
    //})

export default {
    components: {
        EditorContent, ImageManager
    },
    data() {
        return {
            editor: null,
            selectedImage: null
        }
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },
    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },
    mounted() {
        this.editor = new Editor({
            content: this.modelValue,
            extensions: [
                StarterKit,
                TextAlign.configure({
                    types: ['heading', 'paragraph', 'image'],
                }),
                Link.configure({
                    openOnClick: false,
                }),
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
                Image,
                Superscript,
                Subscript
            ],
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.getHTML());
            },
        });

        Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))
    },
    beforeUnmount() {
        this.editor.destroy();
    },
    methods: {
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },
        setImage(image) {
            //const url = window.prompt('Image url', "/images/")

            // cancelled
            if (image == null) {
                return
            }
            //// update link
            this.editor
                .chain()
                .focus()
                .setImage({ src: "/images/" + image })
                .run()
        }
    }
}
</script>
<style>
    pre {
        background: #ced4da;
        color: #222222;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
    }

    code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: hidden;
    }

    form td, form th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
    }
    th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
    }

    .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
    }

    .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
    }

    img {
        width: 95%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .img-left {
        display: block;
        float:left;
    }

    .img-right {
        display: block;
        float:left;
    }


    img.ProseMirror-selectednode {
        outline: 2px solid #68cef8;
    }

    p {
        margin: 0;
    }

</style>