<template>
    <div class="container py-3">
        <div class="row mt-2 mb-2 px-2">
            <Content url="skin-stories" title="Skin Stories" h="h1" />
        </div>
        <div v-if="!global.allSkinStories" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-else class="card mb-3 my-2 shadow" v-for="item in global.allSkinStories" :key="item.id">
            <div class="row g-0">
                <div class="col-md-3">
                        <router-link :to="{ path: `/skin-story/${item.url}` }" class="stretched-link">
                            <img class="card-img"  :src=item.image :alt=item.name>
                        </router-link>
                </div>
                <div class="col-md-9">
                    <div class="card-body">
                            <h3 class="card-title py-0">{{item.name}}</h3>
                            <small class="text-muted py-0">by {{item.author}}</small>
                            <p class="card-text py-3">{{item.shortDescription}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Content from '@/components/Content.vue';
    import { toastOptions } from '@/helpers/toastOptions.js';
    import axios from "axios";
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'SkinStories',
        components: { Content },
        computed: {
            ...mapState({
                global: state => state.global
            })
        },
        data() {
            return {
                skinStories: null
            }
        },
        methods: {
            ...mapActions('global', ['saveAllSkinStories']),
            getData() {
            axios.get(process.env.VUE_APP_URL + 'api/skinstories')
                        .then(response => {
                            this.saveAllSkinStories(response.data);
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        })
            }
        },
        beforeMount() {
            this.getData();
        }
    }
</script>
<style>
</style>