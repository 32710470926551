<template>
    <aside class="bg-dark shadow p-4 p-sm-5 mb-5">
        <div class="container">
            <div class="d-flex align-items-center justify-content-between flex-column flex-xl-row text-center text-xl-start">
                <div class="mb-4 mb-xl-0">
                    <h3 class="fs-3 fw-bold text-white">Search protein</h3>
                    <div class="text-white"><p>Search the Skin Atlas database for protein details. Use an UniProt Protein Id.</p></div>
                </div>
                <div class="ms-xl-4">
                    <div class="input-group mb-2 align-content-between">
                        <SearchAutocomplete />
                    </div>
                    <div class="text-white-50">Example: Q6BDS2 or A0A0D9SFE4</div>
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
    import SearchAutocomplete from '@/components/SearchAutocomplete.vue'

    export default {
        name: 'SearchProteinBar',
        components: {
            SearchAutocomplete
        }
    }
</script>
<style>
    .simple-typeahead-input {
        width: 100%;
    }
</style>