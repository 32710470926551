<template>
    <div class="container py-3">
        <Content url="global-dermatology" title="SkinStories – The Global Dermatology Edition" h="h1"/>
    </div>
</template>
<script>
    import Content from '@/components/Content.vue';

    export default {
        name: 'GlobalDermatology',
        components: { Content }
    }
</script>