<template>
    <div class="container">
        <div class="row py-3">            
            <h1>{{skinstory.name}}</h1>
            <span class="text-muted px-3">Author: {{skinstory.author}}</span>    
        </div>
            
        <div class="row">
            <div class="col-sm-8">                
                <p class="my-4 text-fundal" v-html="skinstory.content"></p>                
            </div>
            <div class="col-sm-4">
                <div class="mb-5">
                    <img :src="'' + skinstory.image" style="object-fit: cover; width: 100%; max-height: 500px; float:left; margin-top:15px; margin-right:10px; margin-bottom:10px;" :alt=skinstory.name>                    
                </div>

                <h3 class="py-3 gray">More skin stories:</h3>
                
                <div v-if="!this.$store.state.global.allSkinStories" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else>
                    <div v-for="item in this.$store.state.global.allSkinStories" :key="item.id">
                        <div v-if="item.id != skinstory.id && item.id <= skinstory.id && item.id >= skinstory.id - 5" class="card mb-3">
                            <div class="row g-0">
                                <div class="col-md-3">
                                    <router-link :to="{ path: `/skin-story/${item.url}`}" force="true" class="stretched-link">
                                        <img :src="'' + item.image" class="img-fluid rounded-start" alt="">
                                    </router-link>
                                </div>
                                <div class="col-md-9">
                                    <div class="card-body">
                                        <h6 class="card-title">{{item.name}}</h6>
                                        <p class="card-text"><small class="text-muted">by: {{item.author}}</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 card border-0">
                        <router-link to="../skin-stories" class="btn btn-lg btn-primary float-right">All Skin Stories</router-link>
                    </div>                 
                </div>
                
                <!-- <div v-if="nav">
                    <h3 class="py-4">Table of contents:</h3>
                    <div class="list-group" id="nav" v-html="nav"></div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import { mapState, mapActions } from 'vuex'
    import { toastOptions } from '@/helpers/toastOptions.js';

    export default {
        name: 'SkinStory',
        data() {
            return {
                skinstory: {},
                nav: '',
                ...mapState({
                    global: state => state.global
                }) 
            }
        },
        methods: {
            ...mapActions('global', ['saveAllSkinStories']),
            async getData() {
                await axios.get(process.env.VUE_APP_URL + `api/skinstories/${this.$route.params.url}`)
                    .then(response => {
                        this.skinstory = response.data;
                        this.loaded = true;

                        if (!this.global.allSkinStories)
                        {
                            console.log("getting all skin stories");
                            axios.get(process.env.VUE_APP_URL + 'api/skinstories')
                            .then(response => {
                                this.saveAllSkinStories(response.data);
                            })
                            .catch(error => {
                                this.$toast(error.message, toastOptions.error);
                            })
                        }

                        
                        /* if (window.DOMParser) {

                            this.skinstory.content = this.skinstory.content.replaceAll("<br>", "<br></br>").replaceAll("<hr>", "<hr></hr>");

                            var parser = new DOMParser();

                            var document = parser?.parseFromString("<div>" + this.skinstory.content + "</div>", "text/xml");
                            let index = 0;

                            for (var i = 1; i <= 6; i++) {
                                var headers = document.getElementsByTagName('h' + i);
                                for (var j = 0; j < headers.length; j++) {
                                    headers[j].className = 'h';
                                    headers[j].setAttribute("id", "list-item-" + index++);
                                }
                            }

                            var heads = document.getElementsByClassName('h');

                            if (heads[0]?.innerHTML != "This page contains the following errors:") {
                                for (var t = 0; t < heads.length; t++) {
                                    this.nav += '<a class="list-group-item list-group-item-action c' +
                                        heads[t].tagName.toLowerCase() +
                                        '" href="#' + heads[t].id + '">' +
                                        heads[t].innerHTML +
                                        '</a>';
                                }
                            }
                        }
                        else {
                            this.nav = null;
                            console.log("outside");
                        } */
                    })
                    .catch((error) => {
                        this.$toast(error.message, toastOptions.error);
                    });
            }
        },
        mounted(){
            this.getData();
        },
        watch: {
            '$route' (to, from) {
                console.log(to);
                if (to != from && to.path.includes("skin-story")) this.getData();
            }
        }
    }
</script>
<style>
    #nav a.list-group-item.list-group-item-action.ch1 {
        padding: 6px 6px 6px 6px;
    }

    #nav a.list-group-item.list-group-item-action.ch2 {
        padding: 6px 6px 6px 15px;
    }

    #nav a.list-group-item.list-group-item-action.ch3 {
        padding: 6px 6px 6px 30px;
    }

    #nav a.list-group-item.list-group-item-action.ch4 {
        padding: 6px 6px 6px 45px;
    }

    #nav a.list-group-item.list-group-item-action.ch5 {
        padding: 6px 6px 6px 60px;
    }

    #nav a.list-group-item.list-group-item-action.ch6 {
        padding: 6px 6px 6px 75px;
    }
</style>
