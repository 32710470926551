<template>
    <div class="home">
        <div style="background-color: #1a2332;">
            <div style="overflow: hidden; max-height:500px; min-height:250px; align-content: center; ">
                <img style="width:110%; margin-left:-5%; height:auto;" src="skin-to-protein-inverted-bg.jpg" />
            </div>
            <div class="container" style="margin-top:-200px;">
                <div class="row align-items-center">
                    <div class="col-1"></div>
                    <div class="col-10">
                        <h1 class="display-4 text-light shaddow">The Human Skin Atlas</h1>
                        <p class="lead text-light shaddow mt-2 mb-5">
                            The Human Skin Atlas is a research initiative aiming to advance translational skin research by providing a resource with mass spectrometry-based proteome data from healthy and diseased skin.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-4"></div>
        <HomeDatasets />
        <SearchProteinBar />
        <SkinStoriesPaginated/>
        <Collaborators />
    </div>
</template>
<script>
    import Collaborators from '@/components/Collaborators.vue'
    import SearchProteinBar from '@/components/SearchProteinBar.vue'
    import HomeDatasets from '@/components/HomeDatasets.vue'
    import SkinStoriesPaginated from '@/components/SkinStoriesPaginated.vue'

    export default {
        components: { Collaborators, SearchProteinBar, HomeDatasets, SkinStoriesPaginated }        
    }
</script>


